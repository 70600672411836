// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
let WebFont = require('webfontloader');

WebFont.load({
    google: {
        families: ['Cantata One']
    },
    typekit: {
        id: 'cqh8bcl'
    }
});

import "bootstrap"
import "./typekit"
import 'js-loading-overlay'

import "./pages/index"

(function ($) {

    let loadFunction = function () {
        $('.toggle').children('.menu-trigger').removeClass('active');
        $('.navigation').children('.right').removeClass("show");

        if ($(this).scrollTop() > 1) {
            logoSmall();
        }

        if (window.location.hash !== "") {
            let position = $(window.location.hash).offset().top - 200;
            $("html, body").animate({scrollTop: position}, 0, "swing");
        }

        $('body').removeClass('loading');

        $('a[href^="#"]').click(function () {
            let speed = 500;
            let href = $(this).attr("href");
            let target = $(href == "#" || href == "" ? 'html' : href);
            let position = target.offset().top - 200;
            $("html, body").animate({scrollTop: position}, speed, "swing");
            return false;
        });
    }

    let logoLarge = function () {
        $('.brand').removeClass("scroll");
    }

    let logoSmall = function () {
        $('.brand').addClass("scroll");
    }

    let ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1) {
        // iOS系端末用
        window.addEventListener('pageshow', function () {
            loadFunction();
        });
    } else {
        $(window).on('load', function () {
            loadFunction();
        });
    }


    $('a:not([href^="#"]):not([target])').on('click', function (e) {
        e.preventDefault(); // ナビゲートをキャンセル
        let url = $(this).attr('href'); // 遷移先のURLを取得
        if (url !== '') {
            $('body').addClass('loading');  // bodyに class="fadeout"を挿入
            setTimeout(function () {
                window.location = url;  // 0.8秒後に取得したURLに遷移
            }, 300);
        }
        return false;
    });

    $(window).scroll(function () {
        if ($(this).scrollTop() > 1) {
            logoSmall();
        } else {
            logoLarge();
        }
    });

})(jQuery);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)