(function($) {
    $(document).ready(function(){

        $_right = $('.navigation').children('.right');

        $('.toggle').click(function () {
            if ($(this).children('.menu-trigger').hasClass('active')) {
                $(this).children('.menu-trigger').removeClass('active');
                $_right.removeClass('show');
            } else {
                $(this).children('.menu-trigger').addClass('active');
                $_right.addClass('show');
            }
        });

        new Swiper('.swiper-container', {
            speed: 1000,
            spaceBetween: 0,
            effect: 'fade',
            autoplay: {
                delay: 3000,
            },
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'custom',
                renderCustom: function (swiper, current, total) {
                    let pagesElements = '';


                    for (let i = 1; i <= total; i++) {
                        pagesElements = pagesElements + '<span class="pagination' + (i === current ? ' current' : '') + '"></span>';
                    }

                    return pagesElements;
                }
            },
        });
    });
})(jQuery);